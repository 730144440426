import CityForm from '@/views/master/trips/city/CityForm.vue'
import {
  getAllKota
} from '@/domain/usecase'
import '@/assets/styles/scss/master/trips/city/city.scss'
import {
  onMounted,
  ref,
  getCurrentInstance
  // watch,
  // reactive
} from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default {
  name: 'City',
  components: {
    CityForm
  },
  setup() {
    const app = getCurrentInstance()
    const store = useStore()
    const inputSearch = ref('')
    const sendInputSearch = ref('')
    const idBus = ref('')
    const totalRecords = ref(0)
    const sliderActive = ref(false)
    const dataSource = ref([])
    const dataForm = ref(null)
    const route = useRoute()
    const filters = {
      search: '',
      pageNumber: 1,
      pageSize: 10,
    }
    const {
      // $confirm,
      $strInd,
      $toast
    } = app!.appContext.config.globalProperties
    const redirectAdd = () => {
      dataForm.value = null
      sliderActive.value = true
    }
    const getAllKotaSource = async (pageNumber: any = filters.pageNumber) => {
      store.dispatch('showLoading')
      filters.search = sendInputSearch.value
      filters.pageNumber = pageNumber
      const response = await getAllKota(filters)
      if (!response.error) {
        totalRecords.value = response.result.Count
        dataSource.value = response.result.Data
      } else {
        console.log(response)
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 1500
        })
      }
      store.dispatch('hideLoading')
    }
    const onPage = (event: any) => {
      getAllKotaSource(event.page + 1)
    }

    const editData = (data: any) => {
      dataForm.value = data
      sliderActive.value = true
    }
    const searchData = async (val: any) => {
      sendInputSearch.value = val
      await getAllKotaSource(1)
    }
    onMounted(() => {
      getAllKotaSource()
    })
    const closeSidebar = () => {
      sliderActive.value = false
    }
    return {
      idBus,
      sliderActive,
      redirectAdd,
      dataSource,
      getAllKotaSource,
      onPage,
      dataForm,
      editData,
      closeSidebar,
      totalRecords,
      inputSearch,
      searchData,
      sendInputSearch,
      store,
      route,
      filters
    }
  },
}
