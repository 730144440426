import {
  reactive,
  ref,
  getCurrentInstance,
  onMounted,
  watch
} from 'vue'
import {
  submitNewKota,
  getPulau,
  getGroupKota,
  submitNewGroupKota
} from '@/domain/usecase'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers,
  alpha
} from '@vuelidate/validators'
import { useStore } from 'vuex'
import PermissionEnum from '@/utils/enum/permissions'
import { checkPermission } from '@/utils/helpers/checkPermission'

export default {
  name: 'CityForm',
  props: {
    data: Object
  },
  emits: ['closeSidebarCity', 'reloadData'],
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const propertiesProps = reactive(props)
    const title = ref('Tambah Kota')
    const islandOption = ref([])
    const cityGroupOption = ref([])
    const op = ref()
    const modalCityGroup = ref(false)
    const cityGroup = ref(null)
    const nameCityGroup = ref(null)
    const dataForm = reactive({
      isActive: true,
      province: null,
      codeCity: '',
      cityName: null,
      groupCity: null as any,
      islandId: null
    })
    const submitted = ref(false)
    const rules = {
      codeCity: {
        required: helpers.withMessage('Kode Kota harus diisi', required),
        alpha: helpers.withMessage('Hanya menerima karakter alfabet', alpha),
      },
      cityName: {
        required: helpers.withMessage('Nama Kota harus diisi', required),
        // alpha: helpers.withMessage('Hanya menerima karakter alfabet', alpha),
      },
      province: {
        required: helpers.withMessage('Provinsi harus diisi', required),
        // alpha: helpers.withMessage('Hanya menerima karakter alfabet', alpha),
      },
      groupCity: {
        required: helpers.withMessage('Grup Kota harus diisi', required)
      }
    }
    const v$ = useVuelidate(rules, dataForm)
    const {
      // $confirm,
      $strInd,
      $toast
    } = app!.appContext.config.globalProperties
    const store = useStore()

    const closeSidebar = () => {
      emit('closeSidebarCity')
    }
    const checkErorResponse = async (response: any) => {
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
      } else {
        if (propertiesProps.data) {
          $toast.add({
            severity: 'success',
            // summary: response.result.title,
            detail: response.result.detail ?? response.result.Detail,
            group: 'bc',
            life: 1500
          })
        } else {
          $toast.add({
            severity: 'success', detail: 'Berhasil disimpan', group: 'bc', life: 3000
          })
        }
        emit('reloadData')
        closeSidebar()
      }
    }

    const submitProccess = async () => {
      store.dispatch('showLoading')
      let dataSend = {}
      if (propertiesProps.data) {
        dataSend = {
          Id: propertiesProps.data.Id,
          Kode: dataForm.codeCity.toUpperCase(),
          Nama: dataForm.cityName,
          Provinsi: dataForm.province,
          PulauId: dataForm.islandId,
          IsActive: dataForm.isActive,
          MsGroupKotaId: dataForm.groupCity
          // MsGroupKota: {
          //   Id: dataForm.groupCity,
          //   Nama: nameCityGroup.value
          // }
        }
      } else {
        dataSend = {
          Kode: dataForm.codeCity.toUpperCase(),
          Nama: dataForm.cityName,
          Provinsi: dataForm.province,
          PulauId: dataForm.islandId,
          IsActive: dataForm.isActive,
          MsGroupKotaId: dataForm.groupCity
          // MsGroupKota: {
          //   Id: dataForm.groupCity,
          //   Nama: nameCityGroup.value
          // }
        }
      }
      const response = await submitNewKota(dataSend)

      checkErorResponse(response)
    }
    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      submitProccess()
    }

    const setObjMsGroupUser = (name: any, id: any) => {
      nameCityGroup.value = name
      dataForm.groupCity = id
      if (op.value) {
        op.value.hide()
      }
    }

    const fetchIsland = async () => {
      const response = await getPulau()
      if (!response.error) {
        islandOption.value = response.result
      }
    }
    // console.log('masukkk', propertiesProps)
    if (propertiesProps.data) {
      console.log('masuk edit', propertiesProps.data)
      title.value = 'Edit Kota'
      dataForm.isActive = propertiesProps.data.IsActive
      dataForm.province = propertiesProps.data.Provinsi
      dataForm.codeCity = propertiesProps.data.Kode
      dataForm.cityName = propertiesProps.data.Nama
      dataForm.islandId = propertiesProps.data.PulauId
      setObjMsGroupUser(propertiesProps.data?.MsGroupKotaNama, propertiesProps.data?.MsGroupKotaId)
    }

    const fetchGroupKota = () => {
      getGroupKota().then((response: any) => {
        cityGroupOption.value = response.result
      })
    }

    const openModalGroupCity = () => {
      modalCityGroup.value = true
    }

    const closeDialogGroupCity = () => {
      modalCityGroup.value = false
      cityGroup.value = null
    }

    const processSubmitGroupCity = () => {
      const data = {
        Nama: cityGroup.value
      }
      submitNewGroupKota(null, data).then((result) => {
        if (!result.error) {
          setObjMsGroupUser(result.result.Nama, result.result.Id)
          fetchGroupKota()
          // nameCityGroup.value = result.result.Nama
          // dataForm.groupCity = result.result.Id
          modalCityGroup.value = false
          $toast.add({
            severity: 'success',
            // summary: response.result.title,
            detail: $strInd.toast.successSubmit,
            group: 'bc',
            life: 1500
          })
        } else {
          $toast.add({
            severity: 'error',
            // summary: response.result.title,
            detail: $strInd.toast.errorSubmit,
            group: 'bc',
            life: 1500
          })
        }
      })
    }

    const submitGroupCity = () => {
      processSubmitGroupCity()
    }

    watch(() => dataForm.groupCity, (newVal: any, oldVal: any) => {
      console.log('ini udah new value', newVal)
      console.log('ini udah old value', oldVal)
    })

    const groupInput = (evt: any) => {
      op.value.toggle(evt)
    }
    onMounted(() => {
      fetchIsland()
      fetchGroupKota()
    })
    return {
      fetchIsland,
      islandOption,
      closeSidebar,
      title,
      submitData,
      checkErorResponse,
      submitProccess,
      submitted,
      v$,
      dataForm,
      groupInput,
      op,
      cityGroupOption,
      modalCityGroup,
      cityGroup,
      openModalGroupCity,
      closeDialogGroupCity,
      submitGroupCity,
      setObjMsGroupUser,
      nameCityGroup,
      checkPermission,
      PermissionEnum
    }
  }
}
